import React, { useState } from "react";
import { formatNumber } from "../Utils/helpers";

const ExpandableTable = ({ data, currency }) => {
  const [expandedParents, setExpandedParents] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [expandedId, setExpandedId] = useState();

  const toggle = (id) => {
    setExpanded(!expanded);
    toggleParent(id);
    setExpandedId(id);
  };

  const toggleParent = (parentId) => {
    if (expandedParents.includes(parentId)) {
      setExpandedParents(expandedParents.filter((id) => id !== parentId));
    } else {
      setExpandedParents([...expandedParents, parentId]);
    }
  };

  return (
    <div className="table-bottom sales-table">
      <table
        style={{
          width: "100%",
        }}
      >
        <tr
          style={{
            borderWidth: "0px",
            borderBottom: "1px solid #f4f4f4",
            width: "100%",
            borderCollapse: "collapse",
            background: "transparent",
            color: "#4c4c4c",
            textAlign: "center",
          }}
        >
          <th> </th>
          <th align="center" scope="col" style={{ padding: ".7rem" }}>
            Channel Name
          </th>
          <th align="center" scope="col">
            # of Bets
          </th>
          <th align="center" scope="col">
            Running Bets
          </th>
          <th align="center" scope="col">
            Settled Bets
          </th>
          <th align="center" scope="col">
            Stake Bets
          </th>
          <th align="center" scope="col">
            Winnings
          </th>
        </tr>
        {/* <tbody> */}
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <tr className="dgItemStyle" key={index}>
              <td>
                {item.children && <button
                  onClick={() => toggle(item.id)}
                  style={{ cursor: "pointer" }}
                >
                  {expanded && expandedId === item?.id ? "-" : "+"}
                </button>}
              </td>
              <td align="center">{item.channel}</td>
              <td align="center">{formatNumber(item.noOfBets)}</td>
              <td align="center">{formatNumber(item.runningBets)}</td>
              <td align="center">{formatNumber(item.settledBets)}</td>
              <td align="center">{currency}{formatNumber(item.totalStake)}</td>
              <td align="center">{currency}{formatNumber(item.winnings)}</td>
            </tr>
            {expandedId === item?.id &&
              expanded &&
              item?.children.map((child) => (
                <tr className="dgItemStyle" key={index}>
                  <td align="center"></td>
                  <td align="center">{child.name}</td>
                  <td align="center">{child.bets}</td>
                  <td align="center">{child.running_bets}</td>
                  <td align="center">{child.settled_bets}</td>
                  <td align="center">{child.stake_bets}</td>
                  <td align="center">{child.winning}</td>
                </tr>
              ))}
          </React.Fragment>

        ))}
      </table>
    </div>
  );
};

export default ExpandableTable;
