import React, { useEffect, useState } from "react";
// import { generate, generateToday } from "../../Services/apis";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_MODAL } from "../../Redux/types";
import GenerateForm from "../../Components/GenerateForm";

/**
 * packages
 */

export const LastApproved = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const generateData = async () => {
    setLoading(true);

    const payload = {
      agent_id: user.id,
    };
    // await generate(payload)
    //   .then((r) => {
    //     setLoading(false);
    //     setDetails(r?.data);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    generateData();
  }, []);

  const generateReport = async ({ e, inputObject }) => {
    e.preventDefault(e);
    setLoading(true);

    const payload = {
      agent_id: user.id,
      other_sales: inputObject?.otherSales,
      other_payout: inputObject?.otherPayout,
    };
    // await generateToday(payload)
    //   .then((r) => {
    //     setLoading(false);
    //     setDetails(r?.data);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });

    closeModal();
  };

  const closeModal = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };
  return (
    <>
      <div className="homeShop last-approved">
        <div className="table">
          <div className="head">
            <h4>Last Approved</h4>
            <div>
              <button
                onClick={() =>
                  dispatch({
                    type: SHOW_MODAL,
                    payload: {
                      open: true,
                      title: "Generate Report",
                      component: (
                        <GenerateForm generateReport={generateReport} />
                      ),
                    },
                  })
                }
              >
                Generate
              </button>
            </div>
          </div>

          <div className="flex">
            <p>
              <strong>Opening Balance</strong>
            </p>
            <p>{details?.opening_balance}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Normal Sales</strong>
            </p>
            <p>{details?.normal_sales}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Normal Payout</strong>
            </p>
            <p>{details?.normal_payout}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Virtual Sales</strong>
            </p>
            <p>{details?.virtual_sales}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Virtual Payout</strong>
            </p>
            <p>{details?.virtual_payout}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Cash In</strong>
            </p>
            <p>{details?.cash_in}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Cash Out</strong>
            </p>
            <p>{details?.cash_out}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Expenses</strong>
            </p>
            <p>{details?.expenses}</p>
          </div>

          <div className="flex">
            <p>
              <strong>Other Sales</strong>
            </p>
            <p>{details?.other_sales}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Other Payout</strong>
            </p>
            <p>{details?.other_payout}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Casino Sales</strong>
            </p>
            <p>{details?.casino_sales}</p>
          </div>

          <div className="flex">
            <p>
              <strong>Online Withdrawal</strong>
            </p>
            <p>{details?.online_withdrawal}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Closing Balance</strong>
            </p>
            <p>{details?.closing_balance}</p>
          </div>
        </div>
      </div>
    </>
  );
};
