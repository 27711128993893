import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createWithdraw,
  verifyWithdraw,
} from "../../Redux/actions/onlineWithdraw";

const Withdraw = ({ toggle, code }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.withdrawal);
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);

  const { verifyRequest, verifyLoading } = data;

  const [inputObject, setObject] = useState({
    username: "",
    balance: "",
    password: "",
    amount: "",
    withdrawCode: "",
  });

  const approve = () => {
    dispatch(createWithdraw({ withdraw_code: code }));
    toggle();
  };

  useEffect(() => {
    dispatch(verifyWithdraw({ withdraw_code: code }));
  }, []);

  useEffect(() => {
    const { balance } = verifyRequest;
    setObject({
      username: balance?.withdrawRequest?.account_holder,
      balance: verifyRequest?.balance?.user?.balance,
      amount: balance?.withdrawRequest?.amount,
      withdrawCode: balance?.withdrawRequest?.withdraw_code,
    });
  }, [verifyRequest]);

  return (
    <div>
      {verifyRequest &&
      verifyRequest?.message !== "Token Does Not Exist or Invalid" ? (
        <>
          <h4 className="text-modal">
            Are you sure you want to approve this request?
          </h4>
          <div className="withdraw">
            <form className="new-entry">
              <div className="entry-field">
                <label>Username</label>
                <input
                  type="text"
                  name="opening"
                  value={inputObject.username}
                  disabled={true}
                />
              </div>
              <div className="entry-field">
                <label>Amount</label>
                <input
                  type="number"
                  name="opening"
                  value={inputObject.amount}
                  disabled={true}
                />
              </div>
              <div className="entry-field">
                <label>Code</label>
                <input
                  type="text"
                  name="opening"
                  value={inputObject.withdrawCode}
                  disabled={true}
                />
              </div>
              <div className="entry-field">
                <label>Account Balance</label>
                <input
                  type="number"
                  name="opening"
                  disabled={true}
                  value={inputObject.balance}
                />
              </div>
            </form>
            <div className="expense-btn">
              <input
                type="button"
                name="expenses"
                value="YES"
                className="btn-green"
                onClick={() => approve()}
              />
              <input
                type="button"
                name="expenses"
                value="CANCEL"
                className=" btn-red"
                onClick={() => toggle()}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="withdraw-btn">
          <h4 className="text-modal">Token Does Not Exist or Invalid</h4>
          {/* <input
            type="button"
            name="expenses"
            value="CANCEL"
            className=" btn-red"
            onClick={() => toggle()}
          /> */}
        </div>
      )}
    </div>
  );
};

export default Withdraw;
