import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFile, faX } from "@fortawesome/free-solid-svg-icons";
import ExpandableTable from "../../Components/Table";
import { salesReport } from "../../Services/apis";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { formatDate } from "../../Utils/helpers";

const Sales = () => {
  const dispatch = useDispatch();
  const [activeTab, setActive] = useState(1);
  const [sales, setSales] = useState([]);
  const [salesSummary, setSalesSummary] = useState(null);
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const { user } = useSelector(state => state.auth);

  const [filterData, setFilterData] = useState({
    from: moment().toDate(),
    to: moment().toDate(),
    page_size: 15,
    period: 'today',
    product: 'all',
  });

  const fetchResult = (id) => {
    const data = { ...filterData };
    data.from = formatDate(data.from, "DD-MM-YYYY");
    data.to = formatDate(data.to, "DD-MM-YYYY");
    data.id = id;
    salesReport(data)
      .then((res) => {
        if (res.success) {
          setSales(res.data?.sales);
          setSalesSummary(res.data?.summary);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error("Unable to fetch results"));
  };

  const handleChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
    if (name === 'from' || name === 'to') {
      setFilterData({ ...filterData, period: 'custom', [name]: value })
    } else {
      setFilterData({ ...filterData, [name]: value });
    };
  };

  const setDateRange = (e) => {
    const period = e.target.value;
    switch (period) {
      case "today":
        setFilterData({
          ...filterData,
          from: moment().toDate(),
          to: moment().toDate(),
          period
        });
        break;
      case "yesterday":
        setFilterData({
          ...filterData,
          from: moment().subtract(1, "day").toDate(),
          to: moment().subtract(1, "day").toDate(),
          period
        });
        break;
      case "custom":
        setFilterData({
          ...filterData,
          period
        });
        break;
      default:
        break;
    }
    // fetchResult();
  };

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt" style={{ marginTop: "-1rem" }}>
                  <h4 className="p-4 commission-head">Sales Report</h4>
                </div>
                <div className="cashbook">
                  <ul className="t-header ">
                    <li
                      style={{ marginLeft: "-2.5rem" }}
                      onClick={() => setActive(1)}
                      className={
                        activeTab === 1 ? `active tab-item ml` : "tab-item ml"
                      }
                    >
                      ALL PRODUCTS ANALYSIS
                    </li>
                    <li
                      onClick={() => setActive(2)}
                      className={
                        activeTab === 2 ? `active tab-item` : "tab-item"
                      }
                    >
                      SPORTS BY CHANNEL
                    </li>
                    <li
                      onClick={() => setActive(3)}
                      className={
                        activeTab === 3 ? `active tab-item` : "tab-item"
                      }
                    >
                      VIRTUAL BY CHANNEL
                    </li>
                  </ul>
                  <p
                    className="p-4"
                    style={{
                      background: "white",
                      color: "",
                      marginTop: "-.1px",
                      marginBottom: ".05rem",
                      // borderBottom: ".5px solid red",
                    }}
                  >
                    Use the date flters below to generate the report.
                  </p>
                  <div className="radio">
                    <h3>Select your filter options</h3>
                    <div className="radio-group">
                      <div className="input-group">
                        <input
                          type="radio"
                          name="period"
                          value="today"
                          checked={filterData.period === 'today'}
                          onChange={(e) => setDateRange(e)}
                          id="today"
                        />
                        <label htmlFor="today">Today</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="period"
                          value="yesterday"
                          checked={filterData.period === 'yesterday'}
                          onChange={(e) => setDateRange(e)}
                          id="yesterday"
                        />
                        <label htmlFor="Yesterday">Yesterday</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="day"
                          value="custom"
                          checked={filterData.period === 'custom'}
                          onChange={(e) => setDateRange(e)}
                          id="custom"
                        />
                        <label htmlFor="Manual">Manual</label>
                      </div>
                    </div>
                    <div className="radio-group">
                      <div className="input-group">
                        <input
                          type="radio"
                          name="product"
                          value="all"
                          id="All"
                          onChange={(e) => handleChange("product", 'all')}
                          checked={filterData.product === 'all'}
                        />
                        <label htmlFor="All">All</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="product"
                          value="retail"
                          checked={filterData.product === 'retail'}
                          onChange={(e) => handleChange("product", 'retail')}
                          id="retail"
                        />
                        <label htmlFor="Retail">Retail</label>
                      </div>
                      <div className="input-group">
                        <input
                          type="radio"
                          name="product"
                          value="online"
                          onChange={(e) => handleChange("product", 'online')}
                          checked={filterData.product === 'online'}
                          id="online"
                        />
                        <label htmlFor="Online">Online</label>
                      </div>
                    </div>
                  </div>
                  <div className="date-group">
                    <div className="input-group date">
                      <label htmlFor="today">Date From:</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={filterData.from}
                        className="dropdownFiltoData"
                        maxDate={moment().toDate()}
                        onChange={(date) =>
                          handleChange("from", date)
                        }
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="today">Date To :</label>{" "}
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        maxDate={moment().toDate()}
                        selected={filterData.to}
                        className="dropdownFiltoData"
                        onChange={(date) =>
                          handleChange("to", date)
                        }
                      />
                    </div>
                  </div>
                  <div className="proceed">
                    <div className="cancel-btn">
                      <FontAwesomeIcon
                        icon={faX}
                        style={{
                          color: "red",
                        }}
                      />
                      <button>Cancel</button>
                    </div>
                    <div className="proceed-btn" onClick={() => fetchResult(user?.id)}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: "green",
                        }}
                      />
                      <button>Proceed</button>
                    </div>
                  </div>
                  {sales.length > 0 &&
                    <React.Fragment>
                      <ExpandableTable data={sales} currency={SportsbookGlobalVariable.Currency} />
                      <div
                        className="date-group "
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="export-btn">
                          <FontAwesomeIcon
                            icon={faFile}
                            style={{
                              color: "white",
                            }}
                          />
                          <button>Export to Excel</button>
                        </div>
                        <div className="export-btn">
                          <FontAwesomeIcon
                            icon={faFile}
                            style={{
                              color: "white",
                            }}
                          />
                          <button>Export to CSV</button>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
