import React, { useState } from "react";

const GenerateForm = ({ generateReport }) => {
  const [inputObject, setInputObject] = useState({});

  const handleChange = (e) => {
    e.preventDefault();
    setInputObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      className="new-entry"
      onSubmit={(e) => generateReport({ e, inputObject })}
    >
      <div className="entry-field">
        <label>Other Sales</label>
        <input type="number" name="otherSales" onChange={handleChange} />
      </div>
      <div className="entry-field">
        <label>Other Payout</label>
        <input type="number" onChange={handleChange} name="otherPayout" />
      </div>
      <div className="btn-create">
        <button type="submit">Generate</button>
      </div>
    </form>
  );
};

export default GenerateForm;
