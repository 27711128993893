import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBranchSummary, getTellerSummary } from "../../Services/apis";

/**
 * packages
 */

export const Dashboard = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const toggle = () => {};
  useEffect(() => {
    getBranchDetails();
  }, [dispatch]);

  useEffect(() => {
    getTellerDetails();
  }, [dispatch]);

  const click = (item) => {
    setDetails(item);
  };

  const getBranchDetails = async () => {
    setLoading(true);
    await getBranchSummary()
      .then((r) => {
        setLoading(false);
        setDetails(r?.data);
      })
      .catch((err) => {
        console.log(err.response);

        setLoading(false);
      });
    toggle();
  };

  const getTellerDetails = async () => {
    setLoading(true);
    await getTellerSummary()
      .then((r) => {
        setLoading(false);
        setData(r?.data);
      })
      .catch((err) => {
        console.log(err.response);

        setLoading(false);
      });
    toggle();
  };
  return (
    <div className="Riquadro">
      <div className="CntSX">
        <div className="CntDX">
          <div id="s_w_PC_PC_panelSquare ">
            <div className="cashbook">
              <div className="RiquadroNews Reg">
                <div className="Cnt" style={{ marginTop: "-1rem" }}>
                  <h4 className="p-4 commission-head">Dashboard</h4>
                </div>
              </div>
              <div className="homeShop last-approved dashboard">
                <div className="table" style={{ background: "white" }}>
                  <div className="head">
                    <h4 style={{ background: "#01d46d;", color: "white" }}>
                      Branch Summary
                    </h4>
                  </div>

                  <div className="flex">
                    <p>
                      <strong>Manager Balance</strong>
                    </p>
                    <p>{details?.shopManagerBalance}</p>
                  </div>
                  <div className="flex">
                    <p>
                      <strong>Total Teller Balance</strong>
                    </p>
                    <p>{details?.totalCashierBalance}</p>
                  </div>
                  <div className="flex">
                    <p>
                      <strong>Branch Balance</strong>
                    </p>
                    <p>{details?.shopBalance}</p>
                  </div>
                  <div className="flex">
                    <p>
                      <strong>Total Sales</strong>
                    </p>
                    <p>{details?.totalSales}</p>
                  </div>
                  <div className="flex">
                    <p>
                      <strong>Total Payout</strong>
                    </p>
                    <p>{details?.totalPayout}</p>
                  </div>
                  <div className="flex">
                    <p>
                      <strong>Total Expenses</strong>
                    </p>
                    <p>{details?.totalExpenses}</p>
                  </div>
                </div>
              </div>
              <div className="table-bottom" style={{ margin: "2rem 1rem" }}>
                <ul
                  className="t-header"
                  style={{ background: "#01d46d;", color: "white" }}
                >
                  <li className="tab-item">Teller Summary</li>
                </ul>
                <table className="bottom-table">
                  <tr className="tr">
                    <th align="center" scope="col">
                      Name
                    </th>
                    <th align="center" scope="col">
                      Opening
                    </th>
                    <th align="center" scope="col">
                      Sales
                    </th>
                    <th align="center" scope="col">
                      cashin
                    </th>
                    <th align="center" scope="col">
                      CashOut
                    </th>
                    <th align="center" scope="col">
                      Payout
                    </th>
                    <th align="center" scope="col">
                      Balance
                    </th>
                    {/* <th align="center" scope="col">
                      Action
                    </th> */}
                  </tr>
                  <tbody>
                    {loading
                      ? "LOADING...."
                      : data &&
                        data?.map((item) => (
                          <tr className="dgItemStyle">
                            <td align="center"> {item?.username}</td>
                            <td align="center"> -</td>
                            <td align="center"> {item?.sales}</td>
                            <td align="center"> {item?.cashIn}</td>
                            <td align="center"> {item?.cashOut}</td>
                            <td align="center"> {item?.payout}</td>
                            <td align="center"> {item?.balance}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
