import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { createCashout } from "../../Redux/actions/expenses";
import { SHOW_MODAL } from "../../Redux/types";

const CreatCashIn = ({ toggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [inputObject, setObject] = useState({});

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const creatCash = async (e) => {
    e.preventDefault();
    const payload = {
      branch_id: user?.id,
      amount: inputObject.amount,
      comment: inputObject.comment,
    };

    // await dispatch(createCashout(payload));
    await dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };

  return (
    <form className="expense-form">
      <div className="expense-input">
        <label>AMOUNT</label>
        <input type="number" onChange={handleChange} name="amount" />
      </div>
      <div className="expense-input">
        <label>COMMENT</label>
        <input type="text" onChange={handleChange} name="comment" />
      </div>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value=" CREATE"
          className="btn-green"
          onClick={(e) => creatCash(e)}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() =>
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            })
          }
        />
      </div>
    </form>
  );
};

export default CreatCashIn;
