import { useEffect, useState } from "react";
import React, { useSelector } from "react-redux";
import { useParams } from "react-router";
import { initializeTransaction } from "../../Services/apis";
import { formatNumber } from "../../Utils/helpers";

const DepositForm = ({ history}) => {
  const [amount, setAmount] = useState("");
  const [busy, setBusy] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const {type} = useParams();
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const [paymentSuccess, setPaymentSuccess] = useState("");
  

  useEffect(() => {
    getPaymentMethod()
  }, [type]);

  const getPaymentMethod = () => {

  }
  
  const updateAmount = (value) => {
    if (value === 0) {
      setAmount(0);
      return;
    }
    let currentAmount = amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    const newAmount = currentAmount + value;
    setAmount(newAmount);
  };

  const submit = (e) => {
    e.preventDefault();
    setBusy(true);
    // dispatch({ type: SET_LOADIN, payload: { show: true, message: "" } });
    initializeTransaction({ amount: amount, payment_method: type })
      .then((res) => {
        setBusy(false);
        if (res.success) {
          setAmount("");
          if(type === 'sbengine') {
            setPaymentSuccess(res.data);
          } else {
            window.location.href = res.url;
          }
        } else {
          setErrMsg(res?.message);
         
        }
      })
      .catch((err) => {
        setBusy(false)
      });
  };

    return (
      <div className="deposit">
      <div className="deposit-step">
        <div className="left">
          <h3 style={{ textTransform: "capitalize" }}>{type}</h3>
          <p>Find below-outlined steps on how to fund your wallet.</p>
          <ul>
            <li>1. Put in your intended amount of deposit.</li>
            <li>2. Select your deposit medium.</li>
            <li>3. Click on the "Make payment" button</li>
            <li>4. Put in your Bank account/card details.</li>
            <li>5. Put in your 4 digit pin and one-time password (OTP).</li>
            <li>
              4. Reverify your details to ensure you have provided the right
              details i.e amount etc.
            </li>
            <li>4. Complete your request and get funded instantly.</li>
          </ul>
          <p>
            Transaction will expire in 30mins. If you are unable to complete the
            payment within this duration, kindly re-initiate the deposit.
          </p>
        </div>
        <div className="right">
          <div className="top">
            <div className="right-head">
              <img src={''} alt="logo" className="" />
            </div>
            <h4>Balance: {formatNumber(user?.available_balance)}</h4>
          </div>
          <p className="pl-1">(Card/bank) Instant Credit</p>
          <div className="flex by-1">
            <p>Email: </p>
            <h5>{user?.email} </h5>
          </div>
          <div>
            <div className="flex">
              <label className="w-2"></label>
              <ul className="flex-list">
                <li onClick={() => updateAmount(1000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +1000
                </li>
                <li onClick={() => updateAmount(5000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +5,000
                </li>
                <li onClick={() => updateAmount(10000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +10,000
                </li>{" "}
                <li onClick={() => updateAmount(25000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  +25,000
                </li>{" "}
                <li onClick={() => updateAmount(50000)}>
                  <span>{SportsbookGlobalVariable.Currency}</span> <br />
                  50,000
                </li>
              </ul>
            </div>
            <div className="flex my-1">
              <label className="w-2">Amount</label>
              <input
                name="amount"
                type="number"
                autoComplete="off"
                step="100"
                maxLength="5"
                min={SportsbookGlobalVariable.MinDeposit}
                max="10000"
                className="deposit-input"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="flex my-1">
              <label className="w-2">Type</label>
              <select name="amount" disabled type="text">
                <option value="Card">Card</option>
              </select>
            </div>
          </div>
          <div className="flex-list jb by-1">
            <div>
              <p>Fees:None </p>
            </div>
            <div>
              <p>Minimum Deposit: {SportsbookGlobalVariable.MinDeposit} </p>
            </div>
          </div>
          <div className="flex-list pl-1">
            <p>Currency: {SportsbookGlobalVariable.Currency} </p>
          </div>
          <div className="flex-list jb by-1">
            <div>
              {/* <button className="btn start">+ Deposit</button> */}
                <button
                  className="btn start mt20 mb20"
                  // disabled={parseInt(inputObject.amount) === 0}
                  onClick={submit}
                  disabled={busy}
                >
                  {" "}
                  {busy ? 'Processing...' : 'Make Payment'}
                </button>
              </div>
            <div>
              <button className="btn red" onClick={() => history.goBack()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
export default DepositForm;