import React from "react";
import { BonusMathsCard } from "./BonusMathsCard";

export const BonusInformation = ({ data }) => {
  return (
    <div
      className="bonus-information"
      // style={{ background: "rgba(145, 158, 171, 0.16)" }}
    >
      <div
        style={{
          background: "rgba(145, 158, 171, 0.16)",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            fontSize: "1.3rem",
            paddingLeft: ".5rem",
            paddingTop: "1rem",
          }}
        >
          Bonus Table
        </h3>
        <table className="bonus-information-table">
          <tr>
            <th>Avg Selection</th>
            <th>Bonus Rate (GGR Margin {"<"} 80%)</th>
            <th></th>
            {/* <th>Bonus Rate (GGR Margin {">="} 80%)</th> */}
          </tr>
          <tr className="main">
            <td>0-3.00</td>
            <td>5%</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>3.01-6.00</td>
            <td>30%</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>6.01-8.00</td>
            <td>35%</td>
            <td>15%</td>
          </tr>
          <tr>
            <td>8.01+</td>
            <td>40%</td>
            <td>20%</td>
          </tr>
        </table>
      </div>
      {/* <div style={{ background: "rgba(145, 158, 171, 0.16)" }}>
        <BonusMathsCard
          title="Avg. Number of Selections"
          firstLabel="Total Weighted Stake"
          secondLabel="Total Stake"
          thirdLabel="Avg. Selections"
          firstValue={data?.totalWeightedStake}
          secondValue={data?.totalStake}
          symbol={"/"}
          total={data?.averageNoOfSelections}
        />
        <BonusMathsCard
          title="GGR"
          firstLabel="Total Stake"
          secondLabel="Total Winnings"
          thirdLabel="GGR"
          firstValue={data?.totalStake}
          secondValue={data?.totalWinnings}
          symbol={"-"}
          total={data?.grossProfit}
        />
        <BonusMathsCard
          title="GGR Margin"
          firstLabel="GGR"
          secondLabel="Total Stake"
          thirdLabel="GGR Margin"
          firstValue={data?.grossProfit}
          secondValue={data?.totalStake}
          symbol={"/"}
          total={data?.grossProfit / data?.totalStake}
        />
        <BonusMathsCard
          title="Bonus Rate"
          firstLabel="0% of Gross Profit"
          secondLabel="Total Paid Commission"
          thirdLabel="Bonus"
          firstValue={(data?.grossProfit * data?.monthlyBonus) / 100}
          secondValue={data?.turnoverCommissions}
          symbol={"-"}
          total={data?.monthlyBonus}
        />
      </div> */}
      <div>
        <p className="info-note">
          <strong>Important Notice:</strong> The above report has been reviewed
          to match West African Time.
        </p>
        <ul>
          <li>
            <strong>a. The bonus value is given only if:</strong>
          </li>
          <li>i. The total stake for the selected period exceeds 150,000₦.</li>
          <li>ii. The gross profit is not in negative (i.e. it exceeds 0₦).</li>
          <li>
            iii. The total amount of Turnover commission that will be/has been
            paid to you for the selected period is less than the commission %
            amount based on GGR (i.e. X% of GGR minus total Turnover commission
            exceeds 0₦)
          </li>
          <li>
            <strong>b.</strong> If the above 3 conditions are not all met, then
            the report will specify "No Bonus" and you would not be eligible for
            the monthly sports bonus.
          </li>
          <li>
            <strong>Note</strong> Total Estimated Commission is the total
            estimated commission amount of your Sports Turnover Commission for
            the week. For sports periods of prior months, this amount will be 0,
            as all your Turnover Commission would have been paid and is listed
            in the Total Paid Commission box.
          </li>
        </ul>
      </div>
    </div>
  );
};
