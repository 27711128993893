import React from "react";
import { calculateBonus } from "../../../Utils/couponHelpers";
import { SET_COUPON_DATA } from "../../../Redux/types";
import { formatNumber } from "../../../Utils/helpers";

export const Split = ({ couponData, dispatch, globalVar, bonusList }) => {
    const amounts = process.env.REACT_APP_FAST_ADD_AMOUNTS.split(',');

    const updateSystemWinnings = (stake, type) => {
        let coupondata = { ...couponData };
        coupondata.totalStake = stake;

        if (stake !== '') {

            if (type === 'min') {
                coupondata.minStake = stake;
                const totalStake = stake * coupondata.noOfCombos;
                coupondata.stake = totalStake;
                coupondata.totalStake = totalStake;
            } else {
                coupondata.stake = coupondata.totalStake;
                coupondata.minStake = parseFloat(coupondata.stake) / coupondata.noOfCombos;
            }
            // coupondata.totalStake = parseFloat(coupondata.totalStake) + stake;

            // coupondata.exciseDuty = coupondata.totalStake * 0 / 100;
            // coupondata.stake = coupondata.totalStake - coupondata.exciseDuty;

            //calculate winnings
            let minWinnings = parseFloat(coupondata.minOdds) * parseFloat(coupondata.minStake);
            let maxWinnings = parseFloat(coupondata.maxOdds) * parseFloat(coupondata.minStake);
            //calculate bonus
            coupondata.minBonus = calculateBonus(minWinnings, coupondata, globalVar, bonusList);
            coupondata.maxBonus = calculateBonus(maxWinnings, coupondata, globalVar, bonusList);
            coupondata.minGrossWin = parseFloat(coupondata.minBonus) + minWinnings;
            coupondata.minWTH = (coupondata.minGrossWin - coupondata.stake) * process.env.REACT_APP_WTH_PERC / 100;
            coupondata.minWin = coupondata.minGrossWin - coupondata.minWTH;
            coupondata.grossWin = parseFloat(coupondata.maxBonus) + maxWinnings;
            const wthTax = (couponData.grossWin - couponData.stake) * process.env.REACT_APP_WTH_PERC / 100;
            coupondata.wthTax = wthTax < 1 ? 0 : wthTax;
            coupondata.maxWin = coupondata.grossWin - coupondata.wthTax;
        }
        return dispatch({ type: SET_COUPON_DATA, payload: coupondata });

    }

    return (
        <div className="sel integrale">
            <div className="divCpnTipo DI">
                Split Column Bet
            </div>
            <div className="divCpnTipoCnt">
                <div className="CpnTipoRiep">
                    <div className="RiepSX">Min. Odds</div>
                    <div className="RiepDX"><span>{couponData.minOdds?.toFixed(2)}</span></div>
                </div>
                <div className="CpnTipoRiep">
                    <div className="RiepSX">Max. Odds</div>
                    <div className="RiepDX"><span>{couponData.maxOdds?.toFixed(2)}</span></div>
                </div>

                <div className="CpnTipoRiep amountdi">
                    <div className="RiepSX">Amount</div>
                    <div className="RiepDX">
                        <span data-coupon-split-comb="">{couponData.noOfCombos}</span>&nbsp;x&nbsp;
                        <span><input name="split_stake" type="text" value={couponData.minStake} maxlength="5" className="TextBox" style={{ width: '35px' }} />&nbsp;{globalVar.Currency}</span>
                        <span id="spanCautionDI">
                            <img src="./img/Error_small.png" align="middle" style={{ display: 'none' }} />
                        </span>
                    </div>
                </div>
                <div className="CpnTipoRiep stake">
                    <div className="RiepSX">Total Stake</div>
                    <div className="RiepDX"><span className="couponEqual">=&nbsp;</span>
                        <input
                            name="totalStake"
                            type="text"
                            value={couponData.stake}
                            maxLength="5"
                            onChange={(e) => updateSystemWinnings(e.target.value, 'max')}
                            className="TextBox"
                            style={{ width: '35px' }}
                        /><span>&nbsp;{globalVar.Currency}</span></div>
                </div>

                <div className="CpnTipoRiep">

                    <div className="RiepSX">Min. Bonus</div>
                    <div className="RiepDX">
                        <span id="spanBonusMinDI">
                            <span>{formatNumber(couponData.minBonus)}</span></span>&nbsp;{globalVar.Currency}
                    </div>
                </div>
                <div className="CpnTipoRiep">

                    <div className="RiepSX">Max. Bonus</div>
                    <div className="RiepDX">
                        <span id="spanBonusMaxDI">
                            <span>{formatNumber(couponData.maxBonus)}</span></span>&nbsp;{globalVar.Currency}</div>
                </div>
                <div className="CpnTipoRiep High">
                    <div className="RiepSX">Min. Pot. Winnings </div>
                    <div className="RiepDX">
                        <span id="spanVincitaPotMin">
                            <span>{formatNumber(couponData.minGrossWin)}</span></span>&nbsp;{globalVar.Currency}
                    </div>
                </div>

                <div className="CpnTipoRiep grosswin High">

                    <div className="RiepSX">Max. Pot. Winnings</div>
                    <div className="RiepDX">
                        <span>{formatNumber(couponData.grossWin)}</span>&nbsp;{globalVar.Currency}</div>
                    <a title="Update" className="lnkRefresh" href="javascript:;" />
                </div>

            </div>
        </div>
    )
}
